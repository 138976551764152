<template>
  <div class="user-admin">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "LoginLayout",
  components: {
    //
  },
  computed: {
    //
  },
};
</script>
